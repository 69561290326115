import { Box, Container, Flex, Text } from '@chakra-ui/react'
import { Accordion } from './common/Accordion'

export const ContractorQuestions = [
  {
    title: 'Kto sa môže registrovať, ako dodávateľ?',
    content: (
      <Text color="inherit" fontSize="inherit" lineHeight="inherit">
        Každá firma (právnická alebo fyzická osoba), skupina či jednotlivci oprávnení podnikať podľa
        platných zákonov.
        <br />
        Sme silne profesijne zameranou platformou pre oblasť stavebníctva a s tým súvisiacich
        služieb. Vítame preto registráciu profesií zameraných na akúkoľvek fázu realizácií potrebné
        k dodaniu diela v tejto oblasti.
        <br />
        Napríklad, ak klient plánuje výstavbu rodinného domu, jeho dopyt môže postupne vyžadovať
        viaceré profesie zúčastňujúce sa na jednotlivých fázach realizácie výstavby, tzn. od
        projektu až po samotnú stavebnú realizáciu a konečné bývanie vrátane jeho zariadenia
        nábytkom či príslušenstvom k dvoru a záhrade.
      </Text>
    ),
  },
  {
    title: 'Ako sa registruje dodávateľ?',
    content:
      'Ak vykonávate činnosti v oblasti profesií stavebníctva a s tým súvisiacich služieb a chcete mať prístup k všetkým dopytom - Stačí navštíviť portál zadajdopyt.sk a kliknúť na „Registrovať sa“, ďalej ako „Dodávateľ“ a portál vás prevedie jednoduchými krokmi registrácie, tak aby sa nezabudlo na nič, čo potrebuje každá firma pre získavanie zákaziek a postupné budovanie kvalitnej prezentácie.',
  },
  {
    title: 'Aké sú poplatky pre dodávateľa?',
    content: (
      <Text color="inherit" fontSize="inherit" lineHeight="inherit">
        Zadávanie dopytov je pre všetkých úplne zdarma! Rovnako aj registrácia a spravovanie svojho
        účtu pre zadávateľov dopytu je zdarma.
        <br />
        V prípade, že chcete spolupracovať ako dodávateľ akejkoľvek stavebnej práce či s tým
        súvisiacej služby, portál vám umožní registrovať sa, spravovať si svoj účet a prezerať jeho
        obsah.
        <br />
        Vzhľadom k tomu, že platforma je neustále v budovaní a naše plány vám uľahčovať podnikanie
        sú neobmedzené, budeme radi ak stanovenú cenu za úplné využívanie našich služieb budete
        brať, ako príspevok na rozvoj dobrej veci, za čo vám vopred ďakujeme!
      </Text>
    ),
  },
  {
    title: 'Čo ponúka zadajdopyt.sk pre dodávateľa?',
    content: (
      <Text color="inherit" fontSize="inherit" lineHeight="inherit">
        Dodávatelia stavebných prác a s tým súvisiacich služieb môžu využívať tento úžasný portál
        naplno po úspešnej registrácii a zaplatení poplatku.
        <br />
        Získate tým prehľadné užívateľské prostredie portálu s úplným prístupom ku všetkým
        kontaktom, vrátane termínov a miest realizácií a všetkých príloh na stiahnutie, ako sú
        fotky, náčrty, projekty, výkazy – výmery, prípadne súťažné podmienky.
        <br />
        Na jednom mieste na vás budú čakať dopyty od súkromných osôb, zákazky správcov budov,
        firiem, havarijné služby a všetky verejné obstarávania...
        <br />
        Uprednostňujeme využívanie portálu, ale sami si môžete zvoliť či chcete aby vaše dopyty
        podľa odborného zamerania boli preposielané aj na váš e-mail.
        <br />
        Budete si môcť vytvárať vlastné dopyty aj pre prípad subdodávok pre väčšie projekty.
        <br />
        Na každý dopyt budete vedieť okamžite reagovať! Portál vám okrem pohodlnej a prehľadnej
        komunikácie so zákazníkmi umožní vytvárať veľmi flexibilne - ponuky na dopyty.
        <br />
        Všetky ponuky máte na jednom mieste, viete si ich organizovať – ponuky viete doplniť,
        opraviť, vymazať, opakovať a oveľa viac... Úžasné okamžité odozvy jedným kliknutím na
        rozsah, cenu, termíny, prípadne bonus k ponuke.
        <br />
        Pre malé najmä remeselné zákazky niekedy postačuje ponuka jednou vetou, najčastejšie ide o
        materiál, plochu a cenu. Portál vám umožňuje vytvárať aj väčšie viacpoložkové ponuky, ktoré
        si viete jednoducho vytlačiť.
        <br />
        Okrem úžasnej firemnej prezentácie, ktorú uvidia vaši zákazníci dostanete priestor na
        zadávanie rôznych akcií a zvýhodnených ponúk v rámci priamej ponuky!
        <br />
        <strong>A oveľa viac...</strong> lebo platforma je neustále v budovaní a naše plány vám
        uľahčovať podnikanie sú neobmedzené, budeme radi ak stanovenú cenu za úplné využívanie
        našich služieb budete brať, ako príspevok na rozvoj dobrej veci, za čo vám vopred ďakujeme!
        <br />
        Úžasná platforma, ktorú si ihneď zamilujete - registrujte sa!
      </Text>
    ),
  },
]

export const CustomerQuestions = [
  {
    title: 'Kto môže zadávať dopyt?',
    content:
      'Každý kto potrebuje akúkoľvek stavebnú prácu alebo s tým súvisiacu službu. Súkromné osoby, podnikatelia, správcovia bytových domov a ostatných budov, inštitúcie.',
  },
  {
    title: 'Čo získam zadávaním dopytu cez portál?',
    content: (
      <Text color="inherit" fontSize="inherit" lineHeight="inherit">
        Ušetríte čas a peniaze!
        <br /> <br />
        <strong>Zárukou šetrenia vášho času</strong> je prehľadné užívateľské prostredie portálu.
        Sústreďuje dopyty a ponuky na jednom mieste, kde ich viete organizovať – dopyty viete
        doplniť, opraviť, vymazať, opakovať a oveľa viac... Máte okamžitý prístup k uceleným
        informáciám o firmách, k hodnoteniu aj kontaktom. Úžasné okamžité odozvy jedným kliknutím na
        rozsah, cenu, termíny, prípadne bonus k ponuke. Prístup do priamej ponuky, kde môžete
        vyhľadávať ponuky za najlepšie ceny na trhu!
        <br />
        <br />
        <strong>Zárukou šetrenia vašich peniazi</strong> je úplne otvorený trh, jeho nezávislosť
        zaručená rovnakým prístupom k vaším dopytom a zároveň súťaživosť dodávateľských firiem
        ovplyvnená okamžitými feedbackmi zákazníkov, doslova jedným kliknutím na rozsah, cenu,
        termíny, prípadne bonusy ku každej ponuke.
      </Text>
    ),
  },
  {
    title: 'Ako sa zadáva dopyt?',
    content:
      'Stačí kiknúť na „Vytvoriť dopyt“ a portál vás prevedie tromi jednoduchými krokmi, tak aby sa nezabudlo na nič, čo je potrebné pre kvalitné nacenenie vášho dopytu dodávateľmi.',
  },
  {
    title: 'Aké dopyty sa môžu zadávať?',
    content: (
      <Text color="inherit" fontSize="inherit" lineHeight="inherit">
        Dopyty na všetky druhy stavebných prác a s tým súvisiace služby.
        <br />
        Napríklad, ak plánujete výstavbu rodinného domu, uvidia váš dopyt všetky profesie
        zúčastňujúce sa na jednotlivých fázach výstavby, tzn. od projektu až po samotnú stavebnú
        realizáciu a konečné bývanie vrátane jeho zariadenia nábytkom či príslušenstvom k dvoru a
        záhrade.
      </Text>
    ),
  },
  {
    title: 'Je zadávanie dopytu zadarmo?',
    content:
      'Áno, dopyt zadávate úplne zadarmo! Stačí sa v jedinom kroku zaregistrovať vašim mailom a budete mať k dispozícii všetky výhody tejto úžasnej platformy. ',
  },
  {
    title: 'Viem sa registrovať aj bez zadania dopytu?',
    content:
      'Áno, registrovať sa môžete aj bez konkrétneho dopytu a úplne zadarmo. Najmä ak ešte len plánujete alebo čakáte na rozpočet od projektanta. Registráciou sa môžete oboznámiť s užívateľským prostredím a získate prístup k priamym ponukám za najlepšie ceny!',
  },
  {
    title: 'Aké výhody má registrácia?',
    content: (
      <Text color="inherit" fontSize="inherit" lineHeight="inherit">
        Zadávatelia dopytov môžu využívať tento úžasný portál naplno a zadarmo! Čaká na vás
        prehľadné užívateľské prostredie.
        <br />
        Dopyty a ponuky na jednom mieste. Máte okamžitý prístup k uceleným informáciám o firmách, k
        hodnoteniu aj kontaktom. Svoje dopyty viete doplniť, opraviť, vymazať, opakovať a oveľa
        viac...
        <br />
        Úžasné okamžité feedbacky jedným kliknutím na rozsah, cenu, termíny, prípadne bonus k
        ponuke. Prístup do priamej ponuky, kde môžete vyhľadávať ponuky za najlepšie ceny na trhu!
      </Text>
    ),
  },
  {
    title: 'Prečo sa ešte môj dopyt nezverejnil?',
    content: (
      <Text color="inherit" fontSize="inherit" lineHeight="inherit">
        Zadaný dopyt sa zverejňuje okamžite, zároveň prebieha kontrola jej relevantnosti a či je
        všetko v súlade s obchodnými podmienkami. Ak je váš dopyt seriózny a nie je v rozpore s OP
        nebol dôvod na jej pozastavenie či vymazanie.
        <br />
        Mohlo sa stať, že sme sa vám opakovane nevedeli dovolať a nereagovali ste ani na zaslaný
        e-mail. V takomto prípade nám zavolajte späť a dopyt zverejníme okamžite po odstránení
        rozporov.
      </Text>
    ),
  },
  {
    title: 'Aká je záruka kvality dodávateľských prác?',
    content: (
      <Text color="inherit" fontSize="inherit" lineHeight="inherit">
        Táto úžasná platforma sa neustále buduje a systém postupne nastavujeme tak aby sa k vám
        dostávali ponuky len od skúsených profesionálov vo svojom fachu.
        <br />
        Snažíme sa preto pozerať na dodávateľov presne tak, ako keby sme boli zákazníkmi my sami!
        <br />
        Zároveň, ale uznávame pravidlá trhu a preto je dobré aby každý dodávateľ mohol mať prístup k
        vášmu dopytu, lebo aj medzi začínajúcimi firmami môže byť veľa zdatných a poctivých
        dodávateľov.
        <br />
        Sú pre nás preto nesmierne dôležité vaše feedbacky v podobe hodnotení, informácií a
        pripomienok, na základe nich sa systém nielenže vylepšuje ďalej, ale aj umožňuje ostatným
        zákazníkom lepšie sa informovať ešte pred finálnym výberom dodávateľskej firmy.
      </Text>
    ),
  },
  {
    title: 'Koľko čakám na ponuky k dopytu?',
    content: (
      <Text color="inherit" fontSize="inherit" lineHeight="inherit">
        Niektoré dopyty sa neohrejú dlho, už po niekoľkých hodinách môže mať váš dopyt viacero
        takých ponúk z ktorých viete vybrať. V priemere sa čaká 1 až 3 dni, platí pre menšie
        zákazky.
        <br /> Pre väčšie projekty je to dosť individuálne a záleží na charakteru, rozsahu a
        náročnosti budúceho stavebného diela. Tam kde prebieha výberové konanie, termín vypracovania
        a dodania rozpočtovej ponuky je súčasťou podmienok na predkladanie ponúk.
        <br /> Vo všeobecnosti platí, že ak je váš projekt v dopyte jasne a podrobne popísaný,
        ponuky môžete dostať aj okamžite. V opačnom prípade vám môžu potenciálny dodávatelia položiť
        dodatočné otázky, aby presnejšie určili rozsah a podrobnosti projektu, prípadne ešte pred
        nacenením si vyžiadať obhliadku a zameranie stavby.
      </Text>
    ),
  },
  {
    title: 'Sú moje osobné údaje a informácie v dopyte v bezpečí?',
    content: (
      <Text color="inherit" fontSize="inherit" lineHeight="inherit">
        Áno, všetky údaje ktoré poskytujete cez náš portál sú v bezpečí!
        <br /> Aby bola zaručená transparentnosť k prístupu, váš dopyt je zverejnený na otvorenom
        trhu do rozsahu - názov, popis, technická špecifikácia a širšia lokalita.
        <br /> Ostatné informácie, najmä kontakty a obsah príloh sú prístupné iba dodávateľským
        firmám podľa vášho projektu.
      </Text>
    ),
  },
  {
    title: 'Prečo potrebujete moje telefónne číslo a e-mail?',
    content: (
      <Text color="inherit" fontSize="inherit" lineHeight="inherit">
        Telefónne číslo je povinné a pre malé zákazky často najrýchlejší spôsob dohody medzi vami a
        dodávateľom. Portál aj dodávateľ vám vie zavolať v prípade ak treba doplniť dôležitý údaj
        bez ktorého nie je možné presne vyhotoviť cenovú ponuku.
        <br />
        Pomocou vášho e-mailu sa prihlasujete do účtu a chodia na ňu všetky ponuky a ďalšie
        informácie od dodávateľov, čo môžete obmedziť vo vašom profile.
        <br />
        Portál môže telefonicky overiť aj relevantnosť dopytu. Prečo to robíme?
        <br />
        Uvedomujeme si že vytváranie cenových ponúk vie poriadne ubrať z časového fondu každej firme
        a to dvojnásobne platí pri dopytoch na menšie remeselné práce, kde častokrát obkladač alebo
        vodoinštalatér sa musí venovať zasielaniu cenových ponúk z polovice svojho pracovného času.
        <br />
        Preto sa snažíme neseriózne dopyty aj takto filtrovať, prípadne neúplné doplniť o dôležité
        informácie čo nielen že šetrí váš čas, ale najmä zvyšuje šancu na kvalitnejšie cenové ponuky
        a lepšiu odozvu.
      </Text>
    ),
  },
]

export const FaqAccordion = ({ contentType }: { contentType: 'customer' | 'contractor' }) => {
  const faqData = contentType === 'customer' ? CustomerQuestions : ContractorQuestions

  return (
    <Box w="full" bg="brand.fogGray" borderRadius="24px">
      <Container maxW="1040px">
        <Flex direction="column" align="center">
          <Text
            mt={{ base: '64px', md: '80px' }}
            mb={{ base: '40px', md: '48px' }}
            fontFamily="DM Sans Semibold"
            fontSize="32px"
            lineHeight="42px"
            color="brand.blue100"
            textAlign="center"
          >
            Často kladené otázky
          </Text>
          <Flex
            w="full"
            direction={{ base: 'column', md: 'row' }}
            mb={{ base: '64px', md: '105px' }}
          >
            <Flex w="full">
              <Accordion questions={faqData} spacing={{ base: '16px', md: '0px' }} />
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}
