import { ReactNode } from 'react'
import {
  Accordion as ChakraAccordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Circle,
  Flex,
  LayoutProps,
  Text,
  TextProps,
} from '@chakra-ui/react'
import { IconMinus, IconPlus } from '@tabler/icons-react'

interface AccordionContent {
  title: string
  content: ReactNode
}

interface AccordionProps {
  questions: AccordionContent[]
  spacing?: { base: string; md: string }
  titleStyles?: TextProps
  contentStyles?: TextProps
  titlePadding?: LayoutProps['w']
  defaultIndex?: number
}

export const Accordion = ({
  questions,
  spacing,
  contentStyles,
  titleStyles,
  defaultIndex,
  titlePadding = '24px',
}: AccordionProps) => (
  <ChakraAccordion allowToggle w="full" defaultIndex={defaultIndex}>
    {questions.map(({ title, content }, index) => (
      <AccordionItem
        key={title}
        w="full"
        borderTop="1px"
        color="brand.gainsboro"
        {...(questions.length !== index + 1 ? { mb: spacing } : { borderBottom: '1px' })}
      >
        {({ isExpanded }) => (
          <>
            <AccordionButton
              pt={titlePadding}
              pb={isExpanded ? '8px' : titlePadding}
              _focus={{ outline: 'none' }}
            >
              <Flex mr="16px">
                <Circle size="24px" backgroundColor="brand.blue100">
                  {isExpanded ? <IconMinus stroke={1.5} /> : <IconPlus stroke={1.5} />}
                </Circle>
              </Flex>
              <Text
                noOfLines={2}
                flex="1"
                textAlign="left"
                fontFamily="DM Sans Semibold"
                fontSize={{ base: '18px', md: '20px' }}
                lineHeight="30px"
                color="brand.darkBlue100"
                {...titleStyles}
              >
                {title}
              </Text>
            </AccordionButton>
            <AccordionPanel
              py="24px"
              color="brand.darkBlue80"
              fontSize={{ base: '14px', md: '16px' }}
              lineHeight="25px"
            >
              {typeof content === 'string' ? (
                <Text
                  mx={{ base: 2, md: 6 }}
                  color="brand.darkBlue80"
                  fontSize="18px"
                  lineHeight="150%"
                  {...contentStyles}
                >
                  {content}
                </Text>
              ) : (
                <Box mx={{ base: 2, md: 6 }} color="brand.darkBlue80" {...contentStyles}>
                  {content}
                </Box>
              )}
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    ))}
  </ChakraAccordion>
)
